import { Injectable } from '@angular/core';
import { BillpocketService } from 'src/app/shared/services/billpocket.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService extends BillpocketService {

    constructor(http: HttpClient) {
        super(http);
        this.baseUrl = environment.iauthUrl;
    }

    public isAuthenticated(): boolean {
        const token = sessionStorage.getItem('_token');
        if (!token) {
            return false;
        }
        return true;
    }

    public logOut(): void {
        sessionStorage.clear();
        window.location.href = `${environment.iauthUrl}/logout`;
    }
}
