import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';


@Injectable()
export class MatPaginatorIntlEsp extends MatPaginatorIntl {
    constructor() {
        super();
        this.previousPageLabel = 'Página anterior';
        this.nextPageLabel = 'Página siguiente';
        this.itemsPerPageLabel = 'Tamaño de la página';
        this.lastPageLabel = 'Última página';
        this.firstPageLabel = 'Primera página';
    }
}