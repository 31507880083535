import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillpocketService {

  protected baseUrl = 'https://localhost:3000';

  constructor(private http: HttpClient) { }

  get(httpOptions: Object, uri?: string): Observable<any> {
    const url = uri ? `${this.baseUrl}/${uri}` : this.baseUrl;
    return this.http.get(url, httpOptions).pipe(catchError(this.handleError));
  }

  put(httpOptions: Object, body?: Object, url?: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${url}`, body, httpOptions).pipe(catchError(this.handleError));
  }

  post(httpOptions: Object, body?: object, url?: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${url}`, body, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorChannel: string;
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorChannel = 'CLIENT ERROR';
      errorMessage = error.error.message;
    } else {
      errorChannel = `BACKEND ERROR: ${error.status}`;
      errorMessage = error.error;
    }
    if (!environment.production) {
      console.log(errorChannel, errorMessage);
    }
    return throwError(error);
  }
}
