import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(
        private authService: AuthService,
        public location: Location
    ) { }

    canLoad(): boolean {
        if (!this.authService.isAuthenticated()) {
            window.location.href = `${environment.iauthUrl}/login?returnUrl=${location.href}&isLocal=${!environment.production}`;
            const responseUrl = window.location.href.split('#');
            const payload = responseUrl[1].split('=');
            const tokenString = payload[3];
            const xBpUser = payload[payload.length - 1];
            if (xBpUser.includes('Employee')) {
                sessionStorage.setItem('_xBpUser', xBpUser.replace('%3A', ':'));
            }
            const bearerToken = tokenString.replace('&access_token', '').replace('&refresh_token', '');
            sessionStorage.setItem('_token', bearerToken);
            window.location.href = environment.appUrl;
            return false;
        }
        return true;
    }

}