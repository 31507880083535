import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/modules/authentication/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  logOut(): void {
    this.authService.logOut();
  }

}
