<mat-sidenav-container>
	<mat-sidenav #sidenav class="sidenav" fixedInViewport [attr.role]="role" [mode]="mode" [opened]="!this.isHandset">
		<mat-toolbar>
			<img src="../../../assets/billpocket_logo.svg" alt="Billpocket Logo" width="150px">
		</mat-toolbar>
		<mat-divider></mat-divider>
		<mat-nav-list>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon>store</mat-icon> Bodega
						</mat-panel-title>
					</mat-expansion-panel-header>
					<a mat-list-item routerLink="/bodega/config">Posa Configuracion</a>
					<a mat-list-item routerLink="/bodega">Posa Listado</a>
				</mat-expansion-panel>
			</mat-accordion>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon>local_shipping</mat-icon> Envíos
						</mat-panel-title>
					</mat-expansion-panel-header>
					<a mat-list-item routerLink="/envios">Todos</a>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<app-toolbar [sidenav]="sidenav"></app-toolbar>
		<div class="container">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>