// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  iauthUrl: 'https://iauth.billpocket.dev',
  shipmentsUrl: 'https://api.nimitz.billpocket.dev/api/v1',
  appUrl: 'https://nimitz.billpocket.dev',
  localShipmentId: 1,
  idDeliveryCompanies: {
    fedExId: '2',
    iVoyId: '3',
    paquetexpressId: '4',
    paquetexpressIdCdmx: '5'
  },
  posaListApiUrl: 'https://api.nimitz.billpocket.dev/api/v1',
  barcodeLength: 13,
  limitOfConfiguration: 10000,
  urlTrackingCompanies: {
    fedExUrl: 'https://www.fedex.com/apps/fedextrack/?cntry_code=mx&tracknumbers=',
    iVoyUrl: 'https://v2.ivoy.mx/client/app/track/package/',
    paquetexpressUrl: ''
  },
  enterpriseUrl: 'https://api.enterprise.billpocket.dev',
  tarjetaID: '58',
  googleMapsApiKey: 'AIzaSyBSXQxw-OmlE9_4SkEALqPi9oCdrvSoL4w',
  distributorsApiUrl: 'https://api.osprey.billpocket.dev/api/v1/distribuidores?nimitz=1',
  kiddoApiUrl: 'https://api.kiddo.billpocket.dev/api/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
