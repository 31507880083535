import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const xBpUser = sessionStorage.getItem('_xBpUser');
        const token = sessionStorage.getItem('_token');
        const mHeaders = {};

        if (xBpUser && !req.url.match(/kiddo/gm)) {
            mHeaders['X-BP-User'] = xBpUser;
        }

        if (token) {
            mHeaders['Authorization'] = `Bearer ${token}`;
        }

        const authReq = req.clone({
            headers: new HttpHeaders(mHeaders)
        });

        return next.handle(authReq);
    }

}
